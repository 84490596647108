<template>
  <div>
    <MainStepper e1="3" />

    <v-stepper v-model="e1">
      <v-toolbar flat class="pt-1">
        <v-row>
          <v-col cols="4">
            <span class="text-h6 primary--text">EVALUATION</span>
          </v-col>
          <v-col cols="8">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize ml-1 float-right"
                  :loading="loading"
                  @click="goToEvaluationReports"
                  elevation="0"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-chart</v-icon>
                </v-btn>
              </template>
              <span>Evaluation reports</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize ml-1 float-right"
                  :loading="loading"
                  @click="goToSupplierSelection"
                  elevation="0"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </template>
              <span>Back to supplier selection</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-divider class="mx-4" inset vertical></v-divider>

      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" color="info">
          Tenders Selection
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" color="info">
          Applicant Selection
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3" color="info">
          Technical Evaluation
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4" color="info">
          Financial Evaluation
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 5" step="5" color="info">
          Awarding
        </v-stepper-step>

        <v-divider></v-divider>
      </v-stepper-header>
      <v-progress-linear
        color="primary lighten-3"
        buffer-value="0"
        :value="this.progress_value"
        stream
        class="mt-"
      ></v-progress-linear>
      <v-stepper-items>
        <v-form ref="form" v-model="valid">
          <v-stepper-content step="1">
            <div class="">
              <v-data-table
                :headers="headers"
                :items="items"
                sort-by="name"
                class="elevation-1"
                color=""
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title
                      class="error--text text--darken-1 justify-center"
                    >
                      <v-icon class="mr-2 mt-n1" color="error"
                        >mdi-file-document-edit</v-icon
                      >Select Tender
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="loadingDtTender"
                  ></v-progress-linear>
                </template>
                <template v-slot:[`item.closing_date`]="{ item }">
                  {{ item.closing_date }}
                </template>
                <template v-slot:[`item.evaluation_count`]="{ item }">
                  <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-auto d-flex"
                        :elevation="0"
                        dark
                        fab
                        x-small
                        rounded
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                      >
                        <span class="font-weight-bold button">
                          {{ item.evaluation_count }}</span
                        >
                      </v-btn>
                    </template>
                    <span>Numbers of complete evaluations </span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.bidders_count`]="{ item }">
                  <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-auto d-flex"
                        :elevation="0"
                        dark
                        fab
                        x-small
                        rounded
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                      >
                        <span class="font-weight-bold button">
                          {{ item.bidders_count }}</span
                        >
                      </v-btn>
                    </template>
                    <span>Numbers of bidders fro this tender </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.evaluation_complete`]="{ item }">
                  <a v-if="item.evaluation_complete" class="success--text">
                    <v-btn
                      color="success"
                      class="text-capitalize"
                      elevation="0"
                      small
                    >
                      Evaluated
                    </v-btn>
                  </a>
                  <a v-else class="info--text">
                    <v-btn
                      color="info"
                      class="text-capitalize"
                      elevation="0"
                      small
                    >
                      Pending
                    </v-btn>
                  </a>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="my-1" v-if="item.bidders_count > 0">
                    <v-btn
                      color="success"
                      class="mx-1 text-capitalize"
                      elevation="0"
                      @click="nextToStep2(item)"
                    >
                      Evaluate
                      <v-icon color=""
                        >mdi-checkbox-multiple-marked-circle-outline</v-icon
                      >
                    </v-btn>
                  </div>

                  <div class="my-1" v-else>
                    <v-btn
                      color="primary"
                      class="mx-1 text-capitalize"
                      elevation="0"
                      @click="nextToStep2(item)"
                    >
                      Evaluate <v-icon color="">mdi-check-all</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-data-table
              :headers="headers_applicants"
              :items="applicants"
              sort-by="calories"
              class="elevation-0 mb-2"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    class="error--text text--darken-1 justify-center"
                  >
                    <v-icon class="mr-2 mt-n1" color="error"
                      >mdi-file-document-edit</v-icon
                    >Select Applicant
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-auto d-flex"
                        :elevation="0"
                        dark
                        fab
                        x-small
                        rounded
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="backToStep1"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <span>Back one step </span>
                  </v-tooltip>
                </v-toolbar>
                <v-progress-linear
                  indeterminate
                  color="primary"
                  v-if="loadingDtApplicants"
                ></v-progress-linear>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  v-if="item.complete == 1"
                  color="success"
                  class="mx-2 text-capitalize"
                >
                  Evaluated <v-icon small>mdi-check-all</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  class="mx-2 text-capitalize"
                  @click="nextToStep3(item)"
                >
                  Evaluate <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.company_logo_url`]="{ item }">
                <v-img
                  max-height="64"
                  max-width="64"
                  :src="item.company_logo_url"
                  class="my-3 mx-auto"
                  rounded
                  outlined
                  :lazy-src="item.company_logo_url"
                ></v-img>
              </template>
              <template v-slot:no-data>
                <a class="error--text text--darken-1">
                  No applicant found for this tender</a
                >
              </template>
              <template v-slot:[`item.complete`]="{ item }">
                <a v-if="item.complete == 1" class="success--text">
                  <v-btn
                    color="success"
                    class="text-capitalize"
                    elevation="0"
                    small
                  >
                    Evaluated
                  </v-btn>
                </a>
                <a v-else class="info--text">
                  <v-btn
                    color="info"
                    class="text-capitalize"
                    elevation="0"
                    small
                  >
                    Pending...
                  </v-btn>
                </a>
              </template>
              complete
              <!-- samdoh  -->
            </v-data-table>
          </v-stepper-content>
          <v-stepper-content step="3">
            <TenderCard />
            <TenderMandatoryEvaluation ref="mandatory" />
            <TenderTechnicalEvaluation ref="technical" />
            <v-btn
              dark
              color="grey"
              @click="backToStep2"
              class="mb-1 mx-2"
              outlined
            >
              <v-icon>mdi-chevron-left</v-icon> Back
            </v-btn>
            <v-btn dark color="primary" @click="nextToStep4" class="ml-5">
              Continue <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="4">
            <TenderFinancialEvaluation ref="financial" />
            <v-btn color="grey" @click="backToStep3" class="mb-1 mx-2" outlined>
              <v-icon>mdi-chevron-left</v-icon> Back
            </v-btn>
            <v-btn color="primary" @click="nextToStep5" class="mb-1 mx-5" dark>
              Continue <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="5">
            <p class="mt-5">This action requires confirmation</p>
            <v-col md="3">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Corfirm with password"
                hint="At least 6 characters"
                counter
                @click:append="show1 = !show1"
                color="grey"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-btn
              color="grey white--text"
              @click="backToStep4"
              class="mb-1 mx-2 text-capitalize"
              outlined
            >
              <v-icon>mdi-chevron-left</v-icon> Back
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mb-1 mx-2 text-capitalize"
              @click="submitEvaluation"
            >
              Save <v-icon class="ml-2">mdi-check-all</v-icon>
            </v-btn>

            <v-btn
              color="success"
              @click="completeEvaluation"
              class="mb-1 ml-auto d-flex mr-2 text-capitalize"
              dark
            >
              Submit
              <v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon>
            </v-btn>
          </v-stepper-content>
        </v-form>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import TenderCard from "@/components/TenderCard.vue";
import TenderTechnicalEvaluation from "@/components/TenderTechnicalEvaluation.vue";
import TenderFinancialEvaluation from "@/components/TenderFinancialEvaluation.vue";
import TenderMandatoryEvaluation from "@/components/TenderMandatoryEvaluation.vue";
import TenderService from "../../services/tender.service";
import MainStepper from "../../components/MainStepper.vue";
import moment from "moment";

export default {
  components: {
    TenderCard,
    MainStepper,
    TenderTechnicalEvaluation,
    TenderFinancialEvaluation,
    TenderMandatoryEvaluation,
  },
  data() {
    return {
      loading: true,
      progress_value: 20,
      loadingDtTender: true,
      loadingDtApplicants: true,
      show1: false,
      password: "",
      selected: true,
      dialog: false,
      valid: true,
      applicants: [],
      passwordRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      nameRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      emailRules: [
        (v) => !!v || "Valid E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "Min 6 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      items: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        company: {
          company_name: "",
        },
        email: "",
        phone: "",
      },
      defaultItem: {
        name: "",
        company: {
          company_name: "",
        },
        email: "",
        phone: "",
      },

      headers_applicants: [
        {
          text: "Logo",
          value: "company_logo_url",
        },
        {
          text: "Company Name",
          align: "start",
          filterable: true,
          value: "company.company_name",
        },
        {
          text: "Location",
          value: "company.physical_location",
        },
        {
          text: "complete",
          value: "complete",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers: [
        {
          text: "Tender No",
          align: "start",
          filterable: true,
          value: "tender_no",
        },
        {
          text: "Tender Name",
          value: "title",
        },
        {
          text: "Closed",
          value: "closing_date",
        },
        {
          text: "Status",
          value: "evaluation_complete",
        },
        {
          text: "Bidder(s)",
          value: "bidders_count",
        },
        {
          text: "Eval(s)",
          value: "evaluation_count",
        },

        {
          text: "Actions",
          align: "start",
          value: "actions",
          sortable: false,
        },
      ],
      e1: 1,
      docURI:
        "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
      tenderCategories: ["Open", "closed", "On Invitation"],

      breadcrumps: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Admin",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: "Tenders",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],

      suppliers: [
        {
          name: "Software Technologies Limited",
          location: "Westpoint Building",
          type: "Sole Proprietor",
          country: "Kenya",
          email: "support@stl-horizon.com",
          phone: "+254708665345",
        },
        {
          name: "Gulf African Bank",
          location: "EAC Building",
          type: "Limited",
          country: "Kenya",
          email: "gulfbank@sco.ke",
          phone: "+254754654345",
        },
        {
          name: "Kenya Electricity Generating Company (KenGen)",
          location: "Stima Building",
          type: "Private Limited Company",
          country: "Kenya",
          email: "kengen@groups.ke.co",
          phone: "+254708222211",
        },
        {
          name: "Mumias Sugar Company",
          location: "Mumia Central",
          type: "Limited Company",
          country: "Kenya",
          email: "mumias@sugaco.go.ke",
          phone: "+25470000455",
        },
      ],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    user() {
      return this.$store.state.auth.user;
    },
    selectedUser() {
      return this.$store.state.auth.selected_user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },

  created() {
    this.getAllTenders();
  },

  methods: {
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    goToEvaluationReports(){
      this.$router.push("/report");
    },
    completeEvaluation() {
      this.$refs.form.validate();
      if (this.valid == true) {
        let conf = confirm(
          "Are you sure you want to submit evaluation? you cannot undo this action."
        );
        if (conf) {
          this.loading = true;
          let formData = {};
          formData.password = this.password;
          formData.tender_id = this.tender.id;
          formData.applicant_id = this.selectedUser.id;
          formData.evaluator_id = this.user.id;
          return TenderService.completeEvaluation(formData).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Tender evaluation submitted!"
                );
                this.getAllTenders();
                this.e1 = 1;
              } else if (response.status == 201) {
                this.$store.dispatch("alert/error", "Invalid password!");
              } else if (response.status == 202) {
                this.$store.dispatch(
                  "alert/error",
                  "Missing all mandatory documents!"
                );
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Internal server error, please check the form and try again!"
                );
              }
            },
            (error) => {
              console.log(error);
              this.$store.dispatch("alert/error", error.response);
            }
          );
        }
      }
    },
    backToStep1() {
      this.e1 = 1;
      this.progress_value -= 20;
    },
    nextToStep2(item) {
      this.e1 = 2;
      this.progress_value += 20;
      this.$store.dispatch("tender/setSelected", item);
      this.getTenderApplicants();
      this.e1 = 2;
    },
    backToStep2() {
      this.e1 = 2;
      this.progress_value -= 20;
    },
    nextToStep3(item) {
      this.e1 = 3;
      this.progress_value += 20;
      this.$store.dispatch("auth/setSelectedUser", item);
      this.$refs.mandatory.getApplicantUploadedRequiredDocs();
      this.$refs.mandatory.getRequiredScore();
      this.$refs.technical.getApplicantUploadedTechnicalDocs();
      this.$refs.technical.getTechnicalScore();
    },
    backToStep3() {
      this.e1 = 3;
      this.progress_value -= 20;
    },
    nextToStep4() {
      this.e1 = 4;
      this.progress_value += 20;
      this.$refs.financial.getApplicantUploadedFinancialDocs();
      this.$refs.financial.getFinancialScore();
    },
    backToStep4() {
      this.e1 = 4;
      this.progress_value -= 20;
    },
    nextToStep5() {
      this.e1 = 5;
      this.progress_value += 20;
    },
    submitEvaluation() {
      this.$refs.form.validate();
      if (this.valid == true) {
        let conf = confirm(
          "Are you sure you want to submit evaluation? You cannot undo this action."
        );
        if (conf) {
          this.loading = true;
          let formData = {};
          formData.password = this.password;
          formData.tender_id = this.tender.id;
          formData.applicant_id = this.selectedUser.id;
          formData.evaluator_id = this.user.id;
          formData.evaluation_end_date = this.tender.evaluation_end_date;
          return TenderService.submitTenderEvaluation(formData).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Tender evaluation saved!"
                );
                this.$router.push("/report");
              } else if (response.status == 201) {
                this.$store.dispatch("alert/error", "Invalid password!");
              } else if (response.status == 202) {
                this.$store.dispatch(
                  "alert/error",
                  "Missing all mandatory documents!"
                );
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Internal server error, please check the form and try again!"
                );
              }
            },
            (error) => {
              console.log(error);
              this.$store.dispatch("alert/error", error.response);
            }
          );
        }
      }
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    getAllTenders() {
      return TenderService.getEvaluationTenders(this.user.id).then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.loading = false
            this.items = response.data.data;
            this.loadingDtTender = false;
          } else {
            this.items = [];
            this.loadingDtTender = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          this.loadingDtTender = false;
          console.log(error);
        }
      );
    },
    getTenderApplicants() {
      let item = {};
      item.id = this.tender.id;
      let evaluator_id = this.user.id;
      return TenderService.getTenderApplicants(item, evaluator_id).then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.applicants = response.data.data;
            this.loadingDtApplicants = false;
          } else {
            this.applicants = {};
            this.loadingDtApplicants = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.applicants = [];
          this.loadingDtApplicants = false;
          console.log(error);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.updateUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.storeUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>